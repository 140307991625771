import { get } from "lodash"
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      headers: [
        {
          text: "Engine",
          align: "left",
          value: "_engine.name",
          width: 200,
          sortable: true,
        },
        {
          text: "Project",
          align: "left",
          value: "project",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "th-actions",
          align: "center",
          width: 270,
        },
      ],
    }
  },
  methods: {
    getConnections(isActive = false) {
      let self = this
      return new Promise((resolve) => {
        if (self.$refs.table) {
          let getParams = self.$refs.table.getTableServerParams()
          if (self.filterSearch !== "") getParams.search = self.filterSearch
          getParams.is_active = isActive

          self.$store
            .dispatch("rcPlaylistsEnginesListAction", { params: getParams })
            .then(function (response) {
              resolve({
                connections: response.data.data.rc_engines,
                count: response.data.data.full_count,
              })
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          resolve({
            connections: [],
            count: 0,
          })
        }
      })
    },
    redirectKiosk(item, type) {
      let self = this
      let params = {
        type: type,
        rc_engine_key: item._key,
      }
      self.$store
        .dispatch("rcPlaylistsKioskPermLinkHashAction", params)
        .then(function (response) {
          self.$router
            .push({
              path: `/engines/remote-control/kiosk/${type}/${response.data.data.link_hash}`,
            })
            .catch((err) => {})
        })
    },
    initWebsocketConnection() {
      let self = this
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          if (get(data, "path", "") == "1.0.ev.refresh.page") {
            self.fetchTableData()
          }
        }
      }
      self.websocket_common_conn.onopen = function (e) {
        // console.log('onopen')
      }
      self.websocket_common_conn.onclose = function (e) {
        // console.log('conn_websocket_company error --', e)
      }
    },
  },

  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
    }),
  },
}
