<template>
  <DataTableExtended
    ref="table"
    :disable-sort="$vuetify.breakpoint.smAndDown"
    :headers="headers"
    :items="historyConnections"
    :server-items-length="connectionsCountHistory"
    class="elevation-0 border"
    :loading="showTableLoader"
    :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
    :calculate-widths="true"
    v-on:init-table-data="fetchTableData"
    data-testid="table-history-remote-controls"
  >
    <template v-slot:top="{ options, updateOptions }">
      <v-row class="pa-3">
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            v-model="filterSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
            data-testid="filter-search"
          />
        </v-col>
      </v-row>
      <SortMobile
        :headers="headers"
        :options="options"
        @update:options="updateOptions"
        v-if="!$vuetify.breakpoint.smAndUp"
      />
    </template>
    <template v-slot:[`item._engine.name`]="{ item }">
      <span data-testid="engine-name">
        {{ item._engine.name }}
      </span>
    </template>
    <template v-slot:[`item.project`]="{ item }">
      <span data-testid="project">
        {{ item.project }}
      </span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        small
        color="blue-grey"
        class="white--text mr-3"
        :to="
          '/engines/remote-control/engine/' +
          item._engine.name +
          '/project/' +
          item.project +
          '/' +
          item._key
        "
        v-if="$can('rc.playlist', 'ws_rc') || $can('read', 'Superadmin')"
        data-testid="btn-view-playlists"
      >
        View playlists
      </v-btn>

      <v-menu
        bottom
        offset-y
        dark
        v-if="$can('rc.playlist', 'ws_rc') || $can('read', 'Superadmin')"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            small
            color="blue-grey darken-1"
            class="white--text mr-3"
            data-testid="btn-kiosk-menu"
          >
            Kiosk
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content
              key="new-jersey"
              @click="redirectKiosk(item, 'NJ')"
              style="cursor: pointer"
              data-testid="btn-kiosk-new-jersey"
            >
              <v-list-item-title>New Jersey</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              key="rogers"
              @click="redirectKiosk(item, 'ROGERS')"
              style="cursor: pointer"
              data-testid="btn-kiosk-rogers"
            >
              <v-list-item-title>Rogers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </DataTableExtended>
</template>

<script>
import { mapGetters } from "vuex"
import WsConnMixin from "@/mixins/websocket-connection.js"
import RCListMixin from "@/mixins/remote-control/rc-list"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    DataTableExtended,
    SortMobile,
  },

  mixins: [WsConnMixin, RCListMixin],

  data() {
    return {
      historyConnections: [],
      connectionsCountHistory: 0,
      filterSearch: "",
    }
  },

  created: function () {
    try {
      this.initWebsocketConnection()
    } catch (err) {
      // catch initWebsocketConnection
    }
  },

  watch: {
    filterSearch() {
      this.fetchTableData()
    },
  },

  methods: {
    fetchTableData: function () {
      let self = this
      self.getConnections(false).then((res) => {
        self.historyConnections = res.connections
        self.connectionsCountHistory = res.count
        self.$store.dispatch("saveTableLoaderAction", false)
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
    }),
  },
}
</script>
